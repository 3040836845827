<template>
    <modal
        :id="`connect-${type}`"
        title="Connect to X (Twitter)"
        max-width="600px"
        @close="cancel"
    >
        <template #default>
            <p>
                To start distributing your company content to X (Twitter), you
                need to grant permission to
                <b>SocialBu,</b>
                the service Ampifire uses for secure authentication, to:
            </p>
            <ul class="mt-n2 mb-2">
                <li>Upload and manage your content</li>
                <li>Access and view your X (Twitter) account details</li>
            </ul>
            <p>
                Your privacy is important to us. You can always revoke access to
                your account by visiting
                <a
                    href="https://x.com/settings/connected_apps"
                    target="_blank"
                    class="d-inline-block"
                >
                    X account settings
                </a>
                .
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="cancel">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                :loading="isLinking"
                @click="link"
            >
                Continue to X
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { ConnectSocialBuApp } from '@/mixins';
import { DistributionSites } from '@/types/Company';

@Component({
    components: {
        Modal
    }
})
export default class ConnectTwitter extends ConnectSocialBuApp {
    type = 'twitter';
    siteId = DistributionSites.Twitter;
}
</script>
