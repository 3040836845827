import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';

import { CompanyAccountConnection } from '@/types/Company';

const SocialBuConnectorProps = Vue.extend({
    name: 'SocialBuConnector',
    props: {
        companyId: {
            type: Number,
            default() {
                return 0;
            }
        },
        connection: {
            type: Object as PropType<CompanyAccountConnection | null>,
            default(): CompanyAccountConnection | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class SocialBuConnector extends SocialBuConnectorProps {
    type = '';

    isLinked = Boolean(this.connection?.socialbu);

    @InjectReactive({
        from: 'refreshConnections',
        default() {
            return () => void 0;
        }
    })
    refreshConnections!: () => void;

    connect() {
        this.$store.dispatch('modal/open', `connect-${this.type}`);
    }

    onToggle(isLinked: boolean) {
        if (isLinked) {
            if (!this.connection) {
                this.connect();
            }
        } else {
            if (this.connection) {
                this.$emit(
                    'disconnect',
                    this.companyId,
                    this.connection.distribution_site_id
                );
            }
        }
    }

    onCancel() {
        if (!this.connection) {
            // revert the toggle to OFF position
            this.isLinked = false;
        }
    }
}
