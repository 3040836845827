<template>
    <v-container class="pa-0">
        <v-row align="center" :class="{ 'grey--text': disabled }">
            <v-col cols="auto" class="py-0">
                <v-switch
                    v-model="isLinked"
                    label="YouTube"
                    :loading="loading"
                    :disabled="loading || disabled"
                    hide-details
                    class="mt-0 py-3"
                    @change="onToggle"
                />
            </v-col>
            <v-col v-if="connection?.display_name" class="py-0 overflow-hidden">
                <v-chip small color="secondary" text-color="white">
                    <v-icon left x-small>user</v-icon>
                    {{ connection?.display_name }}
                </v-chip>
            </v-col>
            <!-- in a dialog -->
            <connect-youtube
                :company-id="companyId"
                @cancelled="onCancel"
                @connected="refreshConnections"
            />
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Component from 'vue-class-component';

import ConnectYoutube from './ConnectYoutube.vue';

import SocialBuConnector from '@/mixins/ConnectedApps/SocialBuConnector';

@Component({
    name: 'YoutubeConnector',
    components: {
        ConnectYoutube
    }
})
export default class YoutubeConnector extends SocialBuConnector {
    type = 'youtube';
}
</script>
